import React, { ReactElement, Suspense } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import * as commonStyle from "@common/style/style.scss";
import Header from "@components/Header";
import { ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import RootState from "@state/interfaces";
import * as style from "@components/MainTab/MainTab.style.scss";
import { If } from "@components/If";

const MainTab = (): ReactElement => {
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const { namespace, feature } = objectPermissions;

    return (
        <>
            <Header />
            <If condition={!activeProject.publickey}>
                <div className={style.sectionWrapper}>
                    <Suspense fallback={<h2>Loading...</h2>}>
                        <div className={commonStyle.tabsContainer}>
                            <NavLink
                                to="/projects"
                                className={({ isActive }) =>
                                    `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                }
                            >
                                {window.locales.projects}
                            </NavLink>
                            {namespace?.includes(PermissionEnum.GET) && (
                                <NavLink
                                    to="/namespaces"
                                    className={({ isActive }) =>
                                        `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                    }
                                >
                                    {window.locales.namespaces}
                                </NavLink>
                            )}
                            {feature?.includes(PermissionEnum.GET) && (
                                <NavLink
                                    to="/features"
                                    className={({ isActive }) =>
                                        `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                    }
                                >
                                    {window.locales.features}
                                </NavLink>
                            )}
                            {feature?.includes(PermissionEnum.GET) && (
                                <NavLink
                                    to="/overrides"
                                    className={({ isActive }) =>
                                        `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                    }
                                >
                                    {window.locales.overrides}
                                </NavLink>
                            )}
                            {objectPermissions[ObjectEnum.DISCOVERY_POOL_CONFIG]?.includes(PermissionEnum.GET) && (
                                <NavLink
                                    to="/pools"
                                    className={({ isActive }) =>
                                        `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                    }
                                >
                                    {window.locales.pools}
                                </NavLink>
                            )}
                            {namespace?.includes(PermissionEnum.GET) && (
                                <>
                                    <NavLink
                                        to="/auth"
                                        className={({ isActive }) =>
                                            `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                        }
                                    >
                                        {window.locales.auth}
                                    </NavLink>
                                    <NavLink
                                        to="/access"
                                        className={({ isActive }) =>
                                            `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                        }
                                    >
                                        {window.locales.access}
                                    </NavLink>
                                </>
                            )}
                            {namespace?.includes(PermissionEnum.GET) && (
                                <NavLink
                                    to="/itassets"
                                    className={({ isActive }) =>
                                        `${commonStyle.link} ${isActive && commonStyle.activeLink}`
                                    }
                                >
                                    {window.locales.itAssets}
                                </NavLink>
                            )}
                        </div>
                    </Suspense>
                </div>
            </If>
            <Outlet />
        </>
    );
};

export default MainTab;
